<template>
  <div class="forms">
    <v-toolbar flat>
      <v-toolbar-title>Editar Formulario</v-toolbar-title>
    </v-toolbar>
    <v-container fluid>
      <v-row justify="center" class="mx-8">
        <base-error :error="error"></base-error>
      </v-row>
      <v-form v-if="!isLoading" v-model="isValid" ref="form" @submit="submit" class="new__forms__form ma-5">
        <v-row class="mb-2">
          <v-col class="pa-0">
            <v-text-field outlined :rules="rules.issueTypeName" label="*Nombre" class="mt-2" style="max-width:420px"
              v-model="name" :error-messages="nameError" @keydown="
                nameError = null
                error = null
              "></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col class="pa-0">
            <v-text-field style="max-width:420px" outlined name="description" label="Descripción" v-model="description">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col class="pa-0">
            <v-select style="max-width:420px" v-model="mutableField" outlined @change="onFieldChange" item-value="id"
              item-text="name" :items="notSelected" label="Agregar campo"></v-select>
          </v-col>
        </v-row>

        <draggable :list="selectedFields" :disabled="!enabled" group="people" @start="dragging = true"
          @end="dragging = false" item-key="id" :move="checkMove" ghost-class="ghost" animation="150">
          <transition-group>
            <div :key="field.id" v-for="(field, index) in selectedFields">
              <v-row v-if="field.isHidden === false" justify="center" class="mb-2 item" style="cursor: pointer;" :key="field.id" draggable
                @drop="onDrop($event, field, index)" @dragover.prevent @dragenter.prevent @drag="dragHandler">
                <v-col cols="12" >
                  <span class="handle">&#8597;</span>
                  <v-row>
                    <v-col cols="7" class="primary">
                      <span class="white--text">
                        {{ field.name }} ({{field.fieldType.name}})<v-btn small style="float:right" color="white" icon
                          @click="removeField(field.id)">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </span>
                    </v-col>
                    <v-col cols="2">
                      <v-checkbox dense hide-details class="ml-8 mt-0" label="Obligatorio" color="primary"
                        v-model="field.required"></v-checkbox>
                    </v-col>
                    <v-col cols="3">
                      <v-btn small rounded outlined color="primary" @click="addNestedQuestion(field)"
                        :disabled="isNestedDisabled(field)">Anidar pregunta</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-if="hasLogic(field)">
                  <v-row class="grey lighten-4">
                    <v-col cols="4">Pregunta</v-col>
                    <v-col cols="2">Condición</v-col>
                    <v-col cols="2">Valor</v-col>
                    <v-col cols="2">Obligatorio</v-col>
                    <v-col cols="2"></v-col>
                  </v-row>
                  <v-row v-for="(logic, index) in logics" :key="index">
                    <template v-if="logic.rootPatherFieldId === field.id">
                      <v-col cols="4">
                        <v-select style="max-width:420px" v-model="logic.to" outlined dense item-value="id"
                          item-text="name" :items="notSelectedCurrent(logic.to, logic.rootPatherFieldId)"></v-select>
                      </v-col>
                      <v-col cols="2">
                        <template v-if="field.fieldType.slug === 'number'">
                          <v-select item-value="id" item-text="name" :items="conditionsNumbers"
                            v-model="logic.condition" outlined dense hide-details></v-select>
                        </template>
                        <template v-else>
                          <v-select item-value="id" item-text="name" :items="conditions" v-model="logic.condition"
                            outlined dense hide-details></v-select>
                        </template>
                      </v-col>
                      <v-col cols="2">
                        <template v-if="field.fieldType.slug === 'yes_no'">
                          <v-select :items="yesNoOptions" v-model="logic.value" outlined dense>
                          </v-select>
                        </template>
                        <template v-else>
                          <v-text-field dense outlined v-model="logic.value"></v-text-field>
                        </template>
                      </v-col>
                      <v-col cols="2">
                        <v-checkbox dense hide-details class="ml-8 mt-0" v-model="logic.required" color="primary" ></v-checkbox>
                      </v-col>
                      <v-col cols="2">
                        <v-btn icon @click="removeNestedQuestion(logic, field)">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </template>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </transition-group>
        </draggable>
        <div class="drag-preview" :style="dragStyle" ref="dragPreview"></div>

        <v-row style="max-width:380px">
          <v-col>
            <v-btn rounded outlined block color="primary" class="mt-5" :to="{ name: 'forms' }">Cancelar</v-btn>
          </v-col>
          <v-col>
            <v-btn rounded :loading="isLoading" type="submit" block color="primary" class="mt-5" :disabled="!isValid">
              Guardar</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>
<script>
import rules from '@/constants/input-validation'
import { to } from '@/helpers'
import { FIELDS_GET_FIELDS, FORMS_GET_FORM, FORMS_UPDATE_FORM } from '@/store/actions.type'
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'

import { errorMessage } from '@/mixins'
export default {
  name: 'FormEdit',
  props: {
    formId: {
      required: true
    }
  },
  created() {
    Promise.all([this.fetchField(), this.fetchForm()]).then(() => {
      this.isLoading = false
    })
  },
  components: {
    draggable
  },
  data() {
    return {
      dragging: false,
      order: 0,
      enabled: true,
      rules,
      selectedFields: [],
      fields: [],
      logics: [],
      newPosition: null,
      mutableField: null,
      isValid: false,
      isLoading: false,
      description: '',
      descriptionError: '',
      name: '',
      nameError: '',
      error: null,
      conditions: [
        {
          id: 'equal',
          name: 'Igual'
        }
      ],
      conditionsNumbers: [
        {
          id: 'equal',
          name: 'Igual'
        },
        {
          id: 'not_equal',
          name: 'Diferente'
        },
        {
          id: 'lower_than',
          name: 'Menor'
        },
        {
          id: 'lower_equal_than',
          name: 'Menor o Igual'
        },
        {
          id: 'greater_than',
          name: 'Mayor'
        },
        {
          id: 'greater_equal_than',
          name: 'Mayor o Igual'
        }
      ],
      yesNoOptions: [
        {
          value: true,
          text: 'Si'
        }, {
          value: false,
          text: 'No'
        }
      ]
    }
  },
  mixins: [errorMessage],
  computed: {
    ...mapGetters(['getFields', 'getFormsForm']),
    notSelected() {
      const selectedIds = this.selectedFields.map(elem => elem.id)
      const logicIds = this.logics.map(elem => elem.to)
      const mergedIds = [...selectedIds, ...logicIds]
      const notSelected = this.getFields.filter(elem => mergedIds.indexOf(elem.id) === -1)
      return notSelected
    },
    dragStyle() {
      return {
        top: `${this.y}px`,
        left: `${this.x}px`
      }
    }
  },
  methods: {
    checkMove: function (e) {
      // console.log(this.selectedFields)
      // const neData = this.newPosition = this.selectedFields
      // console.log(neData)
    },
    dragStartHandler(e) {
      this.dragPreview = e.target.cloneNode(true)
      this.$refs.dragPreview.appendChild(this.dragPreview)

      e.dataTransfer.setDragImage(new Image(), 0, 0)
    },
    dragEndHandler() {
      this.dragPreview.remove()
      this.dragPreview = null
    },
    dragHandler(e) {
      this.x = e.pageX
      this.y = e.pageY
    },
    startDrag(event, item) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('itemID', item.id)
    },
    onDrop(event, field, index) {
      event.stopPropagation()
      event.dataTransfer.getData('itemID')
    },
    isNestedDisabled(field) {
      const enabledTypes = ['short_text', 'number', 'long_text', 'yes_no', 'dropdown']
      if (enabledTypes.includes(field.fieldType.slug)) {
        return false
      } else {
        return true
      }
    },
    notSelectedCurrent(currentId, rootPatherFieldId) {
      const selectedIds = this.selectedFields.map(elem => elem.id)
      const logicIds = this.logics.map(elem => {
        if (elem.to && elem.rootPatherFieldId === rootPatherFieldId) { return elem.to }
      })
      const mergedIds = [...selectedIds, ...logicIds]

      const notSelected = this.getFields.filter(elem => mergedIds.indexOf(elem.id) === -1 || elem.id === currentId)
      return notSelected
    },
    hasLogic(field) {
      if (!this.logics) return false
      const byFrom = this.logics.filter(elem => elem.rootPatherFieldId === field.id)
      if (byFrom.length > 0) {
        return true
      } else {
        return false
      }
    },
    addNestedQuestion(field) {
      this.logics.push({ from: field.id, to: null, condition: null, value: '', rootPatherFieldId: field.id, required: false })
    },
    removeNestedQuestion(logic, field) {
      const logicIndex = this.logics.findIndex(elem => elem.to === logic.to && elem.rootPatherFieldId === field.id)
      this.logics.splice(logicIndex, 1)
    },
    async fetchField() {
      this.isLoading = true
      const [err] = await to(this.$store.dispatch(FIELDS_GET_FIELDS))
      if (err) {
        this.error = err
      } else {
      }
    },
    async fetchForm() {
      this.isLoading = true
      const [err] = await to(
        this.$store.dispatch(FORMS_GET_FORM, this.formId)
      )
      if (err) {
        // @TODO: if 404
        this.error = err
      } else {
        this.name = this.getFormsForm.name
        this.description = this.getFormsForm.description
        this.logics = this.getFormsForm.logic
        this.selectedFields = this.getFormsForm.fields
      }
    },
    onFieldChange(fieldId) {
      this.selectField(fieldId)
      this.$nextTick(() => {
        this.mutableField = null
      })
    },
    selectField(fieldId) {
      const selected = this.getFields.find(elem => elem.id === fieldId)
      selected.isHidden = false
      this.selectedFields.push(selected)
    },
    removeField(fieldId) {
      const selected = this.selectedFields.filter(elem => elem.id !== fieldId)
      this.selectedFields = selected
    },
    async submit(evt) {
      evt.preventDefault()
      this.newPosition = this.selectedFields
      const selectedIds = this.newPosition.map(elem => elem.id)
      const logicTos = this.logics.map(elem => elem)
      const logicToFields = logicTos.filter(e => !selectedIds.includes(e.to))
      const logicFields = logicToFields.map((elem) =>
        ({ id: elem.to, isHidden: true, required: elem.required })
      )

      const form = {
        id: this.formId,
        name: this.name,
        description: this.description,
        fields: [...this.newPosition, ...logicFields],
        logic: this.logics
      }
      this.isLoading = true
      this.error = null
      const [err] = await to(this.$store.dispatch(FORMS_UPDATE_FORM, { form }))
      if (err) {
        this.error = err
        this.isLoading = false
        if (err.status === 422) {
          this.nameError = this.errorMessage(err, 'name')
          this.descriptionError = this.errorMessage(err, 'description')
        }
      } else {
        this.$router.push({ name: 'forms' })
      }
    }
  }
}
</script>

<style>
.list-move {
  transition: .5s;
}

.item {
  padding: 0.3rem;
  margin: 0.1rem;
}

.sortable-ghost {
  border: 1px dashed grey;
  overflow: hidden;
  background: grey;
  opacity: 0.4;
}

.drag-preview {
  transform: translateX(-50%) translateY(-50%) rotate(7deg) translateY(55%);
}

.handle {
  position: absolute;
  margin-top: 13px;
  left: 1%;
}

.itemFild {
  background: yellow !important;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
